import { FC } from "react";
import styled from '@emotion/styled';
import { CSSObject } from '@emotion/react';

type Props = {
  size?: 'S' | 'M' | 'L';
  theme?: 'LIGHT' | 'DARK';
  color?: string;
  localStyles?: CSSObject;
}

const El = styled.svg(({localStyles}: Props) => ({
  ...(localStyles)
}));

export const Fast: FC<Props> = ({ color = 'white', theme = 'LIGHT', size = "S", localStyles }) => {
  return (
    <>
      {size === "S" && 
        <El
          localStyles={localStyles}
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="17"
          fill="none"
          viewBox="0 0 17 17"
      >
        {theme === 'LIGHT' && <path fill="var(--light-1000)" d="M13.06 13.8589C12.1707 13.8589 11.6779 13.3717 11.6779 12.522V4.48388C11.6779 3.62852 12.1707 3.14136 13.06 3.14136H14.6122C15.5072 3.14136 16 3.62852 16 4.47822V12.522C16 13.3717 15.5072 13.8589 14.6122 13.8589H13.06ZM7.72394 13.8589C6.83459 13.8589 6.34177 13.3717 6.34177 12.522V6.13229C6.34177 5.27693 6.83459 4.78977 7.72394 4.78977H9.27606C10.1711 4.78977 10.6639 5.27693 10.6639 6.13229V12.522C10.6639 13.3717 10.1711 13.8589 9.27606 13.8589H7.72394ZM2.38218 13.8589C1.49282 13.8589 1 13.3717 1 12.522V7.79204C1 6.93667 1.49282 6.44951 2.38218 6.44951H3.93429C4.82931 6.44951 5.32213 6.93667 5.32213 7.78637V12.522C5.32213 13.3717 4.82931 13.8589 3.93429 13.8589H2.38218Z" />}
        {theme === 'DARK' && <path fill="var(--dark-1000)" d="M13.06 13.8589C12.1707 13.8589 11.6779 13.3717 11.6779 12.522V4.48388C11.6779 3.62852 12.1707 3.14136 13.06 3.14136H14.6122C15.5072 3.14136 16 3.62852 16 4.47822V12.522C16 13.3717 15.5072 13.8589 14.6122 13.8589H13.06ZM7.72394 13.8589C6.83459 13.8589 6.34177 13.3717 6.34177 12.522V6.13229C6.34177 5.27693 6.83459 4.78977 7.72394 4.78977H9.27606C10.1711 4.78977 10.6639 5.27693 10.6639 6.13229V12.522C10.6639 13.3717 10.1711 13.8589 9.27606 13.8589H7.72394ZM2.38218 13.8589C1.49282 13.8589 1 13.3717 1 12.522V7.79204C1 6.93667 1.49282 6.44951 2.38218 6.44951H3.93429C4.82931 6.44951 5.32213 6.93667 5.32213 7.78637V12.522C5.32213 13.3717 4.82931 13.8589 3.93429 13.8589H2.38218Z" />}
      </El>
      }
      {size === "M" && 
        <El
          localStyles={localStyles}
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          fill="none"
          viewBox="0 0 19 19"
          >
            {theme === 'LIGHT' &&  <path fill="var(--light-1000)" d="M14.9721 15.9306C13.9048 15.9306 13.3134 15.346 13.3134 14.3264V4.68061C13.3134 3.65417 13.9048 3.06958 14.9721 3.06958H16.8346C17.9086 3.06958 18.5 3.65417 18.5 4.67381V14.3264C18.5 15.346 17.9086 15.9306 16.8346 15.9306H14.9721ZM8.56873 15.9306C7.50151 15.9306 6.91012 15.346 6.91012 14.3264V6.6587C6.91012 5.63227 7.50151 5.04768 8.56873 5.04768H10.4313C11.5053 5.04768 12.0967 5.63227 12.0967 6.6587V14.3264C12.0967 15.346 11.5053 15.9306 10.4313 15.9306H8.56873ZM2.15861 15.9306C1.09139 15.9306 0.5 15.346 0.5 14.3264V8.6504C0.5 7.62396 1.09139 7.03937 2.15861 7.03937H4.02115C5.09517 7.03937 5.68656 7.62396 5.68656 8.6436V14.3264C5.68656 15.346 5.09517 15.9306 4.02115 15.9306H2.15861Z" />}
            {theme === 'DARK' &&  <path fill="var(--dark-1000)" d="M14.9721 15.9306C13.9048 15.9306 13.3134 15.346 13.3134 14.3264V4.68061C13.3134 3.65417 13.9048 3.06958 14.9721 3.06958H16.8346C17.9086 3.06958 18.5 3.65417 18.5 4.67381V14.3264C18.5 15.346 17.9086 15.9306 16.8346 15.9306H14.9721ZM8.56873 15.9306C7.50151 15.9306 6.91012 15.346 6.91012 14.3264V6.6587C6.91012 5.63227 7.50151 5.04768 8.56873 5.04768H10.4313C11.5053 5.04768 12.0967 5.63227 12.0967 6.6587V14.3264C12.0967 15.346 11.5053 15.9306 10.4313 15.9306H8.56873ZM2.15861 15.9306C1.09139 15.9306 0.5 15.346 0.5 14.3264V8.6504C0.5 7.62396 1.09139 7.03937 2.15861 7.03937H4.02115C5.09517 7.03937 5.68656 7.62396 5.68656 8.6436V14.3264C5.68656 15.346 5.09517 15.9306 4.02115 15.9306H2.15861Z" />}
        </El>
      }
      {size === "L" && 
        <El
          localStyles={localStyles}
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="none"
          viewBox="0 0 25 25"
          >
            {theme === 'LIGHT' && <path fill="var(--light-1000)" d="M19.7961 21.0741C18.3731 21.0741 17.5846 20.2946 17.5846 18.9351V6.07406C17.5846 4.70548 18.3731 3.92603 19.7961 3.92603H22.2795C23.7115 3.92603 24.5 4.70548 24.5 6.065V18.9351C24.5 20.2946 23.7115 21.0741 22.2795 21.0741H19.7961ZM11.2583 21.0741C9.83535 21.0741 9.04683 20.2946 9.04683 18.9351V8.71152C9.04683 7.34294 9.83535 6.56349 11.2583 6.56349H13.7417C15.1737 6.56349 15.9622 7.34294 15.9622 8.71152V18.9351C15.9622 20.2946 15.1737 21.0741 13.7417 21.0741H11.2583ZM2.71148 21.0741C1.28852 21.0741 0.5 20.2946 0.5 18.9351V11.3671C0.5 9.99853 1.28852 9.21908 2.71148 9.21908H5.19486C6.62689 9.21908 7.41541 9.99853 7.41541 11.358V18.9351C7.41541 20.2946 6.62689 21.0741 5.19486 21.0741H2.71148Z" />}
            {theme === 'DARK' && <path fill="var(--dark-1000)" d="M19.7961 21.0741C18.3731 21.0741 17.5846 20.2946 17.5846 18.9351V6.07406C17.5846 4.70548 18.3731 3.92603 19.7961 3.92603H22.2795C23.7115 3.92603 24.5 4.70548 24.5 6.065V18.9351C24.5 20.2946 23.7115 21.0741 22.2795 21.0741H19.7961ZM11.2583 21.0741C9.83535 21.0741 9.04683 20.2946 9.04683 18.9351V8.71152C9.04683 7.34294 9.83535 6.56349 11.2583 6.56349H13.7417C15.1737 6.56349 15.9622 7.34294 15.9622 8.71152V18.9351C15.9622 20.2946 15.1737 21.0741 13.7417 21.0741H11.2583ZM2.71148 21.0741C1.28852 21.0741 0.5 20.2946 0.5 18.9351V11.3671C0.5 9.99853 1.28852 9.21908 2.71148 9.21908H5.19486C6.62689 9.21908 7.41541 9.99853 7.41541 11.358V18.9351C7.41541 20.2946 6.62689 21.0741 5.19486 21.0741H2.71148Z" />}
        </El>
      }
    </>
  );
}

export default Fast;







